import { CLEAN_USER_INFO, SET_USER_INFO } from './actionTypes';

export const cleanUserInfoState = () => ({
  type: CLEAN_USER_INFO,
  payload: [],
});

export const setUserInfoState = (userInfo) => ({
  type: SET_USER_INFO,
  payload: {
    userInfo,
  },
});
