import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import PropTypes from 'prop-types';

function CustomSpinnerComponent({ text }) {
  return (
    <div className="spinner-container">
      <ProgressSpinner />
      <div className="spinner-overlay-text">
        Loading
        {' '}
        {text}
        ,
        {' '}
        please wait...
      </div>
    </div>
  );
}

CustomSpinnerComponent.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CustomSpinnerComponent;
