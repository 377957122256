import axiosHandlerAuth from "./indexInternal";
import axiosHandler from ".";

const login = async (data) => {
  try {
    const response = await axiosHandlerAuth.post('/login', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
    if (response?.success) {
      const { data: { username, apiKey, role, id } } = response
      return { username, role, id };
    } else {
      const errorMessage = response.response?.data.message || response.response?.data;
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const errorMessage = error.response?.data.message || error.response?.data;
    return { success: false, message: errorMessage };
  }
}

const getLoggedUser = async () => {
  try {
    const response = await axiosHandlerAuth.get('/login');
    if (response?.success) {
      return response
    } else {
      const errorMessage = response.response?.data.message || response.response?.data;
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const errorMessage = error.response?.data.message || error.response?.data;
    return { success: false, message: errorMessage };
  }
}

const logout = async () => {
  try {
    const response = await axiosHandlerAuth.get('/logout');
    if (response?.success) {
      return response
    } else {
      const errorMessage = response.response?.data.message || response.response?.data;
      return { success: false, message: errorMessage };
    }
  } catch (error) {

    const errorMessage = error.response?.data.message || error.response?.data;
    return { success: false, message: errorMessage };
  }
}

const settings = async (data) => {
  try {
    const response = await axiosHandlerAuth.post('/settings', {
      username: data.username,
      current_password: data.oldPassword,
      new_password: data.newPassword,
      confirm_new_password: data.confirmPassword,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
    });
    if (response?.success) {
      return response
    } else {
      const errorMessage = response.response?.data.message || response.response?.data;
      return { success: false, message: errorMessage };
    }
  }
  catch (error) {
    const errorMessage = error.response?.data.message || error.response?.data;
    return { success: false, message: errorMessage };
  }
}

const resetApiKey = () => {
  return axiosHandler.post('/reset');
}

const resetPassword = async (data) => {
  try {
    const response = await axiosHandlerAuth.post('/reset_password', {
      password: data.newPassword,
      confirm_password: data.confirmPassword,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
    });
    if (response?.success) {
      return response
    } else {
      const errorMessage = response.response?.data.message || response.response?.data;
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const errorMessage = error.response?.data.message || error.response?.data;
    return { success: false, message: errorMessage };
  }
}

export { login, getLoggedUser, logout, settings, resetApiKey, resetPassword }