import {
  SET_CAMPAIGNS,
  CLEAN_CAMPAIGNS,
} from './actions/actionTypes';

const initialState = {
  campaigns: {
    total: 0,
    campaigns: [],
  },
};

// eslint-disable-next-line default-param-last
const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS: {
      return {
        total: action.payload.campaigns.total,
        campaigns: action.payload.campaigns.campaigns,
      };
    }
    case CLEAN_CAMPAIGNS: {
      return [];
    }
    default:
      return state;
  }
};

export default campaignsReducer;
