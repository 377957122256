import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const icons = {
  'image/jpeg': 'pi-image',
  'image/png': 'pi-image',
  'image/svg+xml': 'pi-image',
  'application/pdf': 'pi-file-pdf',
  default: 'pi-file',
};

function fileIcon(data) {
  return <i className={`pi ${icons[data.type] || icons.default}`} />;
}

function AttachmentsListComponent({ attachments, setAttachments }) {
  const [selectedEmailTemplates, setSelectedEmailTemplates] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Attachments</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const header = renderHeader();

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-trash" severity="danger" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} onClick={() => setAttachments(attachments.filter((item) => item.name !== value.name))} />
  );

  return (
    <DataTable
      size="small"
      value={attachments}
      paginator
      header={header}
      rows={10}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rowsPerPageOptions={[10, 25, 50]}
      dataKey="name"
      selection={selectedEmailTemplates}
      onSelectionChange={(e) => setSelectedEmailTemplates(e.value)}
      filters={filters}
      filterDisplay="menu"
      globalFilterFields={['name']}
      emptyMessage="No attachments found."
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} attachments"
    >
      <Column style={{ maxWidth: '1rem' }} body={(rowData) => fileIcon(rowData)} />
      <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '14rem' }} />
      <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={(e) => actionBodyTemplate(e)} />
    </DataTable>
  );
}

AttachmentsListComponent.propTypes = {
  attachments: PropTypes.array,
  setAttachments: PropTypes.func.isRequired,
};

AttachmentsListComponent.defaultProps = {
  attachments: [],
};

export default AttachmentsListComponent;
