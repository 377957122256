import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { InputText } from 'primereact/inputtext';
import './ResultsListComponent.scss';
import { statusesInfo } from '../../utils';
import { reportUser } from '../../../../apis/campaigns.ts';
import { useToast } from '../../../../context/ToastContext';
import renderBrowserAndOSIcons from './helpers';
import SubmittedDataInfoComponent from './SubmittedDataInfoComponent';

function EmailResultsListComponent({ data, campaignUrl }) {
  const { timeline, results } = data;
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const toast = useToast();
  const [expandedRows, setExpandedRows] = useState(null);

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    setGlobalFilterValue(value);
  };

  const accept = async (id) => {
    try {
      const response = await reportUser(campaignUrl, id);
      if (typeof response === 'string') {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'User reported successfully',
          life: 3000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred',
        life: 3000,
      });
    }
  };

  const reportAction = (id) => {
    confirmDialog({
      message: `This result will be flagged as reported (RID: ${id})`,
      header: 'Are you sure?',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
    });
  };

  const customizedContent = (item) => (
    <div className="font-italic">
      <p>
        {item.message}
        &ensp;&emsp;
        {new Date(item.time).toISOString().replace(/T/, ' ').replace(/\..+/, '')}
        {item.details && (
        <>
          {renderBrowserAndOSIcons(item.details)}
          {item.message === 'Submitted Data' && <SubmittedDataInfoComponent data={item.details} />}
        </>
        )}
      </p>
    </div>
  );

  const customizedMarker = (item) => (
    <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: statusesInfo[item.message].color }}>
      <i className={statusesInfo[item.message].icon} />
    </span>
  );

  const actionBodyTemplate = (rowData) => (
    <Button
      type="button"
      text
      icon={rowData.reported ? 'pi pi-thumbs-up-fill' : 'pi pi-thumbs-down'}
      rounded
      onClick={() => { reportAction(rowData.id); }}
      disabled={rowData.reported || rowData.status === 'Error'}
    />
  );

  const rowExpansionTemplate = (userData) => {
    const {
      id, first_name: firstName, last_name: lastName, email,
    } = userData;
    const filteredTimeline = timeline.filter((item) => item.email === '' || item.email === email);
    return (
      <Card
        title={`Timeline for ${firstName} ${lastName}`}
        subTitle={(
          <div className="font-italic">
            <p>
              Email:
              {' '}
              {email}
            </p>
            <p>
              Result ID:
              {' '}
              {id}
            </p>
          </div>
        )}
      >
        <Timeline value={filteredTimeline} style={{ display: 'contents' }} marker={customizedMarker} content={(item) => customizedContent(item)} />
      </Card>
    );
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Users</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );

  const header = renderHeader();

  const statusBody = (rowData) => (
    <Tag value={rowData.status} style={{ backgroundColor: statusesInfo[rowData.status].color }} />
  );

  return (
    <>
      <ConfirmDialog />
      <DataTable
        size="small"
        value={results}
        header={header}
        emptyMessage="No user interacted yet."
        dataKey="email"
        globalFilter={globalFilterValue}
        selectionMode="single"
        paginator
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50]}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(e) => rowExpansionTemplate(e)}
      >
        <Column expander style={{ width: '3rem' }} />
        <Column field="first_name" header="First name" sortable filter filterPlaceholder="Search by first name" style={{ minWidth: '13rem' }} />
        <Column field="last_name" header="Last name" sortable filter filterPlaceholder="Search by last name" style={{ minWidth: '13rem' }} />
        <Column field="email" header="Email" sortable filter filterPlaceholder="Search by email" style={{ minWidth: '13rem' }} />
        <Column field="position" header="Position" sortable filter filterPlaceholder="Search by position" style={{ minWidth: '10rem' }} />
        <Column field="status" header="Status" sortable style={{ minWidth: '7rem' }} body={(rowData) => statusBody(rowData)} />
        <Column field="reported" header="Reported" headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
      </DataTable>
    </>
  );
}

EmailResultsListComponent.propTypes = {
  data: PropTypes.object,
  campaignUrl: PropTypes.string.isRequired,
};

EmailResultsListComponent.defaultProps = {
  data: {},
};

export default EmailResultsListComponent;
