import React from 'react';
import { Menu } from 'primereact/menu';
import './SideMenuComponent.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';

export default function SideMenuComponent() {
  const navigate = useNavigate();
  const { role } = useSelector(getUserInfoState);

  const items = [
    {
      label: <u>Statistics</u>,
      items: [
        {
          label: 'Dashboard',
          icon: 'pi pi-chart-bar',
          command: () => { navigate('/'); },
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: <u>Campaigns</u>,
      items: [
        {
          label: 'Campaigns',
          icon: 'pi pi-globe',
          command: () => { navigate('/campaigns'); },
        },
        {
          label: 'Users & Groups',
          icon: 'pi pi-users',
          command: () => { navigate('/groups'); },
        },
        {
          label: 'Templates',
          icon: 'pi pi-envelope',
          command: () => { navigate('/templates'); },
        },
        {
          label: 'Landing Pages',
          icon: 'pi pi-image',
          command: () => { navigate('/landing-pages'); },
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: <u>Settings</u>,
      items: [
        role !== 'customer' && {
          label: 'Sending Profiles',
          icon: 'pi pi-cloud',
          command: () => { navigate('/sending-profiles'); },
        },
        {
          label: 'User Interface',
          icon: 'pi pi-desktop',
          command: () => { navigate('/user-interface'); },
        },
        role !== 'customer' && {
          label: 'Reporting Settings',
          icon: 'pi pi-server',
          command: () => { navigate('/reporting-settings'); },
        },
        role === 'admin' && {
          label: (
            <span>
              User Management
              {' '}
              <sup className="small-icon">
                <i className="pi pi-star-fill" style={{ fontSize: '0.8em' }} />
              </sup>
            </span>
          ),
          icon: 'pi pi-key',
          command: () => { navigate('/user-management'); },
        },
        role === 'admin' && {
          label: (
            <span>
              Webhooks
              {' '}
              <sup className="small-icon">
                <i className="pi pi-star-fill" style={{ fontSize: '0.8em' }} />
              </sup>
            </span>
          ),
          icon: 'pi pi-sync',
          command: () => { navigate('/webhooks'); },
        },
      ].filter(Boolean),
    },
  ];

  return (
    <div className="card flex justify-content-center">
      <Menu model={items} />
    </div>
  );
}
