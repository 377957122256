import axios from 'axios';
import { store } from '../redux/store/store'
import { cleanUserInfoState, setUserInfoState } from '../redux/reducers/actions/userInfoActions';

const baseURL = '/api/';
const axiosHandler = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
let apiKey = '';

axiosHandler.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${apiKey || store.getState().userInfo.apiKey}`;
    return config;
  },
  (error) => Promise.reject(error),
);

// TODO: Improve handle errors
axiosHandler.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      if (response.headers['x-api-key'] || response.data?.data?.apiKey) {
        apiKey = response.headers['x-api-key'] || response.data?.data?.apiKey;
        store.dispatch(setUserInfoState({ apiKey: response.headers['x-api-key'] || response.data?.data?.apiKey }));
      }
      return Promise.resolve(response.data);
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(cleanUserInfoState())
      return
    }
    return Promise.resolve(error)
  },
);

export default axiosHandler;
