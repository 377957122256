import {
  SET_USER_INFO,
  CLEAN_USER_INFO,
} from './actions/actionTypes';

const initialState = {
  name: '',
  role: '',
  apiKey: '',
  csrf: '',
  id: undefined,
};

// eslint-disable-next-line default-param-last
const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      const { name, role, apiKey, csrf, id } = action.payload.userInfo;
      return {
        ...state,
        name: name || state.name,
        role: role || state.role,
        apiKey: apiKey || state.apiKey,
        csrf: csrf || state.csrf,
        id: id || state.id,
      };
    }
    case CLEAN_USER_INFO: {
      return initialState;
    }
    default:
      return state;
  }
};

export default userInfoReducer;
