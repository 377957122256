import React from 'react';

export default function FooterComponent() {
  return (
    <div id="footer">
      <a href="https://tier8.com/" target="blank">
        <img src="/assets/common/footer.png" alt="Footer logo" style={{ width: '200px', height: 'auto' }} />
      </a>
    </div>
  );
}
