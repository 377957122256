import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import withContent from '../withContent';
import { getTemplates } from '../../apis/templates.ts';
import ManageTemplatesModalComponent from '../../components/TemplatesComponent/ManageTemplatesModalComponent/ManageTemplatesModalComponent';
import TemplatesListComponent from '../../components/TemplatesComponent/TemplatesListComponent/TemplatesListComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';

function TemplatesPage() {
  const [templates, setTemplates] = useState();
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await getTemplates();
      if (!Array.isArray(response) && response !== null) {
        setError(true);
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting the templates', life: 3000,
        });
      }
      setTemplates(response || []);
    }
    fetchData();
  }, []);

  return (
    <>
      <Button className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New Template" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      <ManageTemplatesModalComponent visible={visible} setVisible={setVisible} mode="create" />

      {templates === undefined ? (
        <CustomSpinnerComponent text="templates" />
      ) : (
        <>
          {error && (
          <InfoComponent
            title="An error occurred"
            message="Please try refreshing the page. If the error persists, please contact us."
            severity="error"
          />
          )}

          {!error && templates?.length === 0 ? (
            <InfoComponent
              title="No templates"
              message="No templates created yet. Let&apos;s create one!"
              severity="info"
            />
          ) : (
            <TemplatesListComponent templates={templates} />
          )}
        </>
      )}

    </>
  );
}

export default withContent(TemplatesPage);
