import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import PropTypes from 'prop-types';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { statusesInfo } from '../../utils';

HighchartsAccessibility(Highcharts);

function TimelineComponent({ events }) {
  const timelineSeriesData = [];
  events.forEach((event) => {
    if (event.message === 'Campaign Created') {
      return;
    }

    const eventDate = moment.utc(event.time).local();

    timelineSeriesData.push({
      email: event.email,
      message: event.message,
      x: eventDate.valueOf(),
      y: 1,
      marker: {
        fillColor: statusesInfo[event.message].color,
      },
    });
  });

  const options = {
    chart: {
      zoomType: 'x',
      type: 'line',
      height: '200px',
    },
    title: {
      text: 'Campaign Timeline',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        second: '%l:%M:%S',
        minute: '%l:%M',
        hour: '%l:%M',
        day: '%b %d, %Y',
        week: '%b %d, %Y',
        month: '%b %Y',
      },
    },
    yAxis: {
      min: 0,
      max: 2,
      visible: false,
      tickInterval: 1,
      labels: {
        enabled: false,
      },
      title: {
        text: '',
      },
    },
    tooltip: {
      formatter() {
        return `${Highcharts.dateFormat('%A, %b %d %l:%M:%S %P', new Date(this.x))
        }<br>Event: ${this.point.message}<br>Email: <b>${this.point.email}</b>`;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 3,
        },
        cursor: 'pointer',
      },
      line: {
        states: {
          hover: {
            lineWidth: 1,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [{
      data: timelineSeriesData,
      dashStyle: 'shortdash',
      color: '#cccccc',
      lineWidth: 1,
      turboThreshold: 0,
    }],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

TimelineComponent.propTypes = {
  events: PropTypes.array,
};

TimelineComponent.defaultProps = {
  events: [],
};

export default TimelineComponent;
