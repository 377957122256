import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function CustomConfirmationDialog({
  visible,
  onHide,
  onConfirm,
  message,
  header,
}) {
  return (
    <Dialog
      header={header}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={onHide}
      footer={(
        <div>
          <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-text" />
          <Button label="Confirm" icon="pi pi-check" onClick={onConfirm} autoFocus />
        </div>
      )}
    >
      <p>{message}</p>
    </Dialog>
  );
}

CustomConfirmationDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default CustomConfirmationDialog;
