import React from 'react';
import { Divider } from 'primereact/divider';
import PropTypes from 'prop-types';

export default function TitleComponent({ title }) {
  return (
    <div style={{ textTransform: 'uppercase', textAlign: 'left' }}>
      <h1 style={{ color: '#555', marginLeft: '10px' }}>{title}</h1>
      <Divider />
    </div>
  );
}

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
};
