/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import './ManageUserManagementFormComponent.scss';

const rolesOptions = [
  { name: 'Admin', value: 'admin' },
  { name: 'User', value: 'user' },
  { name: 'Customer', value: 'customer' },
];

function ManageUserManagementFormComponent({
  setUser, user, setValidForm, mode, users,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState('user');
  const [selectedParent, setSelectedParent] = useState();
  const usersOptions = users.map((u) => ({
    name: u.username,
    value: u.username,
    id: u.id,
  }));
  usersOptions.forEach((u) => delete u.username);
  const parent = usersOptions.filter((u) => u.id === user?.parent_id)[0];

  const schema = yup.object().shape({
    username: yup.string().min(2, 'Username must be at least 2 characters').max(255, 'Username must be at most 255 characters').required('Username is required'),
    password: yup.string()
      .test('password-min-length', 'Password must be at least 8 characters', (value) => {
        if (dirtyFields.password) {
          return value?.length > 7;
        }
        return true;
      })
      .test('password-mandatory', 'Password is required', () => {
        if (mode !== 'edit' && !dirtyFields.password) {
          return false;
        }
        return true;
      }),
    confirmPassword: yup.string()
      .test('password-match', 'Passwords must match', (value, ctx) => {
        if (dirtyFields.password) {
          return ctx.parent.password === value;
        }
        return true;
      }),
  });

  const defaultValues = {
    username: '',
    password: '',
    confirmPassword: '',
    password_change_required: true,
    account_locked: false,
    role: 'user',
    parent: '',
  };

  const {
    control,
    formState: { errors, isValid, dirtyFields },
    trigger,
    setValue,
    register,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        username: user.username,
        password_change_required: user.password_change_required,
        account_locked: user.account_locked,
        role: user.role.slug,
        parent: parent?.name,
      });
      setSelectedRole(user.role.slug);
      setSelectedParent(parent?.name);
      setUser({ ...user, parent: parent?.name});
    } else {
      setUser({ ...user, password_change_required: true, role: 'user' });
    }
  }, []);

  useEffect(() => {
    setValidForm(isValid);
  }, [isValid]);

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);
  return (
    <form className="flex flex-column gap-2 text-left ml-3">
      <div>
        <Controller
          name="username"
          control={control}
          render={({ field, fieldState }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                Username*
              </label>
              <InputText
                id={field.name}
                placeholder="Username"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  trigger(field.name);
                  setUser({ ...user, username: e.target.value });
                }}
                aria-describedby={`${field.name}-info`}
              />
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                Password*
              </label>
              <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                <span className="p-input-icon-right">
                  <InputText
                    id={field.name}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register(field.name)}
                    type={showPassword ? 'text' : 'password'}
                    value={field.value}
                    className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                    onChange={(e) => {
                      field.onChange(e.target.value); trigger(['password', 'confirmPassword']);
                      setUser({ ...user, password: e.target.value });
                    }}
                    aria-describedby={`${field.name}-info`}
                  />
                  <i
                    className={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                    onClick={() => setShowPassword(!showPassword)}
                    tabIndex="0"
                    role="button"
                    aria-label="Toggle Password Visibility"
                    onKeyDown={(e) => e.key === 'Enter' && setShowPassword(!showPassword)}
                  />
                </span>

                <div>
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            </div>
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                Confirm password*
              </label>
              <div className="w-2 md:w-10 md:flex-order-0 flex-order-1">
                <span className="p-input-icon-right">
                  <InputText
                    id={field.name}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register(field.name)}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={field.value}
                    className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                    onChange={(e) => { field.onChange(e.target.value); trigger('confirmPassword'); }}
                    aria-describedby={`${field.name}-info`}
                  />
                  <i
                    className={showConfirmPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    tabIndex="0"
                    role="button"
                    aria-label="Toggle Confirm Password Visibility"
                    onKeyDown={(e) => e.key === 'Enter' && setShowConfirmPassword(!showConfirmPassword)}
                  />
                </span>
              </div>
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <div className="row">
          <div className="col">
            <Controller
              name="password_change_required"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name}>
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2"
                    onChange={(e) => {
                      field.onChange(e.checked);
                      setUser({ ...user, password_change_required: e.checked });
                    }}
                  />
                  Require the user to set a new password
                </label>
              )}
            />
          </div>
          <div className="col mb-3">
            <Controller
              name="account_locked"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name}>
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2"
                    onChange={(e) => {
                      field.onChange(e.checked); setUser({ ...user, account_locked: e.checked });
                    }}
                  />
                  Account Locked
                </label>
              )}
            />
          </div>
        </div>

        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                Role*
              </label>
              <Dropdown
                id={field.name}
                value={selectedRole}
                onChange={(e) => {
                  setValue(field.name, e.value);
                  setSelectedRole(e.value);
                  setUser({ ...user, role: e.value });
                }}
                options={rolesOptions}
                optionLabel="name"
                placeholder="Select the user's role"
                className="w-full md:w-30rem"
                required
              />
              <div className="mb-1">
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />

        {selectedRole === 'customer'
          && (
            <Controller
              name="parent"
              control={control}
              render={({ field }) => (
                <div className="mb-2">
                  <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                    Parent user*
                  </label>
                  <Dropdown
                    id={field.name}
                    value={selectedParent}
                    onChange={(e) => {
                      setValue(field.name, e.value);
                      setSelectedParent(e.value);
                      setUser({ ...user, parent: e.value });
                    }}
                    options={usersOptions}
                    optionLabel="name"
                    placeholder="Select the parent user"
                    className="w-full md:w-30rem"
                    required
                  />
                  <div className="mb-1">
                    {getFormErrorMessage(field.name)}
                  </div>
                </div>
              )}
            />
          )}
      </div>
    </form>
  );
}

ManageUserManagementFormComponent.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  setValidForm: PropTypes.func.isRequired,
  mode: PropTypes.string,
  users: PropTypes.array.isRequired,
};

ManageUserManagementFormComponent.defaultProps = {
  user: null,
  mode: '',
};

export default ManageUserManagementFormComponent;
