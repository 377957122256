import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function EmailHeadersComponent({ setHeaders, headers }) {
  const [newHeader, setNewHeader] = useState({ key: '', value: '' });

  async function onSubmit() {
    setHeaders((prevHeaders) => [...prevHeaders, { ...newHeader }]);
  }

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Custom headers</h4>
    </div>
  );

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-trash" severity="danger" className="h-3rem w-3rem border-circle" onClick={() => { setHeaders(headers.filter((item) => item.key !== value.key)); }} />
  );

  const header = renderHeader();
  return (
    <>
      <form onSubmit={onSubmit} className="flex flex-column gap-2 text-left mt-5">
        <div className="mb-2">
          <label htmlFor="email_headers" className="block text-500 font-medium mb-2">
            Email Headers
          </label>
          <div className="card flex flex-wrap gap-1 p-fluid">
            <div className="flex-auto">
              <div>
                <InputText
                  id="header-key"
                  placeholder="X-Custom-Header"
                  className="w-full md:w-25rem mb-5"
                  aria-describedby="custom-header-info"
                  value={newHeader.key}
                  onChange={(e) => setNewHeader(
                    (prevHeader) => ({ ...prevHeader, key: e.target.value }),
                  )}
                />
              </div>

            </div>
            <div className="flex-auto">
              <div>
                <InputText
                  id="header-value"
                  placeholder="{{.URL}}-gophish"
                  className="w-full md:w-25rem mb-5"
                  aria-describedby="header-value-info"
                  value={newHeader.value}
                  onChange={(e) => setNewHeader(
                    (prevHeader) => ({ ...prevHeader, value: e.target.value }),
                  )}
                />
              </div>
            </div>
            <div className="flex-auto">
              <Button label="Add Custom Header" disabled={newHeader.key === '' || newHeader.value === ''} className="max-w-15rem mb-5" icon="pi pi-plus" type="button" onClick={() => onSubmit()} autoFocus />
            </div>
          </div>
        </div>
      </form>
      <DataTable
        size="small"
        value={headers}
        header={header}
        dataKey="key"
        emptyMessage="No custom headers yet."
      >
        <Column field="key" header="Header" sortable />
        <Column field="value" header="Value" sortable />
        <Column body={(e) => actionBodyTemplate(e)} style={{ maxWidth: '1.5rem' }} />
      </DataTable>
    </>
  );
}

EmailHeadersComponent.propTypes = {
  headers: PropTypes.array.isRequired,
  setHeaders: PropTypes.func.isRequired,
};

export default EmailHeadersComponent;
