import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

function ProtectedRoute({
  element,
}) {
  return element || <Outlet />;
}
ProtectedRoute.propTypes = {
  element: PropTypes.object,
};

ProtectedRoute.defaultProps = {
  element: undefined,
};

export default ProtectedRoute;
