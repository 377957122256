import React from 'react';
import UserAndGroupsPage from '../pages/UserAndGroups/UserAndGroupsPage';
import AccountSettingsPage from '../pages/AccountSettings/AccountSettingsPage';
import CampaignsPage from '../pages/CampaignsPage/CampaignsPage';
import CampaignResultPage from '../pages/CampaignsPage/CampaignResultPage';
import UserInterfacePage from '../pages/UserInterfacePage/UserInterfacePage';
import SendingProfilesPage from '../pages/SendingProfilesPage/SendingProfilesPage';
import TemplatesPage from '../pages/TemplatesPage/TemplatesPage';
import LandingPagesPage from '../pages/LandingPagesPage/LandingPagesPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import UserManagementPage from '../pages/UserManagementPage/UserManagementPage';
import ReportingSettingsPage from '../pages/ReportingSettings/ReportingSettingsPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import WebhooksPage from '../pages/WebhooksPage/WebhooksPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';

const noAuthRoutes = [
  {
    path: '/login',
    element: <LoginPage title="Login" />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <DashboardPage title="Dashboard" />,
  },
  {
    path: '/campaigns',
    element: <CampaignsPage title="Campaigns" />,
  },
  {
    path: '/campaign/:id',
    element: <CampaignResultPage title="Campaign overview" />,
  },
  {
    path: '/groups',
    element: <UserAndGroupsPage title="Users and groups" />,
  },
  {
    path: '/templates',
    element: <TemplatesPage title="Templates" />,
  },
  {
    path: '/landing-pages',
    element: <LandingPagesPage title="Landing pages" />,
  },
  {
    path: '/sending-profiles',
    element: <SendingProfilesPage title="Sending profiles" />,
  },
  {
    path: '/user-interface',
    element: <UserInterfacePage title="User interface settings" />,
  },
  {
    path: '/reporting-settings',
    element: <ReportingSettingsPage title="Reporting settings" />,
  },
  {
    path: '/user-management',
    element: <UserManagementPage title="Users management" />,
  },
  {
    path: '/webhooks',
    element: <WebhooksPage title="Webhooks" />,
  },
  {
    path: '/account-settings',
    element: <AccountSettingsPage title="Account settings" />,
  },
  {
    path: '/reset',
    element: <ResetPasswordPage title="Reset password" />,
  },
];

const getNoAuthRoutes = () => noAuthRoutes;
const getAuthRoutes = () => authRoutes;

export { getNoAuthRoutes, getAuthRoutes };
