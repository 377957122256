import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptop, faTablet, faMobile,
} from '@fortawesome/free-solid-svg-icons';
import {
  faChrome, faFirefox, faSafari, faApple, faInternetExplorer, faWindows, faOpera, faAndroid, faEdge,
} from '@fortawesome/free-brands-svg-icons';
import UAParser from 'ua-parser-js';
import { Tag } from 'primereact/tag';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { statusesInfo } from '../../utils';

function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const renderBrowserAndOSIcons = (details) => {
  if (!details) {
    return null;
  }

  const errorObj = JSON.parse(details);
  const errorText = errorObj.error;
  if (errorText) {
    return (
      <Accordion className="mt-2">
        <AccordionTab header="View Details">
          <div className="browser-os-info">
            <div className="browser-info">
              <Tag
                value={capitalizeString(Object.keys(errorObj)[0])}
                style={{ backgroundColor: statusesInfo.Error.color }}
              />
              {' '}
              {errorText}
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  const parsedDetails = JSON.parse(details);
  const parser = new UAParser();
  parser.setUA(parsedDetails?.browser?.['user-agent']);
  const result = parser.getResult();

  let browserIcon = faLaptop;
  let browserName = 'Unknown';
  let browserVersion = '';

  if (result.browser.name) {
    switch (result.browser.name) {
      case 'Chrome':
        browserIcon = faChrome;
        browserName = 'Chrome';
        break;
      case 'Firefox':
        browserIcon = faFirefox;
        browserName = 'Firefox';
        break;
      case 'Safari':
        browserIcon = faSafari;
        browserName = 'Safari';
        break;
      case 'IE':
        browserIcon = faInternetExplorer;
        browserName = 'Internet Explorer';
        break;
      case 'Opera':
        browserIcon = faOpera;
        browserName = 'Opera';
        break;
      case 'Edge':
        browserIcon = faEdge;
        browserName = 'Edge';
        break;
      case 'Android Browser':
        browserIcon = faAndroid;
        browserName = 'Android Browser';
        break;
      default:
        browserName = result.browser.name;
        break;
    }
    browserVersion = result.browser.version || '';
  }

  let osIcon = null;
  let osName = 'Unknown';
  let osVersion = '';

  if (result.os.name) {
    switch (result.os.name) {
      case 'Windows':
        osIcon = faWindows;
        osName = 'Windows';
        break;
      case 'Mac OS':
        osIcon = faApple;
        osName = 'Mac OS';
        break;
      case 'iOS':
        osIcon = faApple;
        osName = 'iOS';
        break;
      case 'Android':
        osIcon = faAndroid;
        osName = 'Android';
        break;
      default:
        osName = result.os.name;
        break;
    }
    osVersion = result.os.version || '';
  }

  let deviceIcon = faLaptop;
  let deviceType = 'Desktop/Laptop';

  if (result.device.type) {
    switch (result.device.type) {
      case 'mobile':
        deviceIcon = faMobile;
        deviceType = 'Mobile';
        break;
      case 'tablet':
        deviceIcon = faTablet;
        deviceType = 'Tablet';
        break;
      default:
        break;
    }
  }

  return (
    <div className="browser-os-info">
      <div className="browser-info">
        <FontAwesomeIcon icon={browserIcon} className="fa-fw mr-2" />
        {browserName}
        {' '}
        {browserVersion && `(Version: ${browserVersion})`}
      </div>
      <div className="os-info">
        {osIcon && <FontAwesomeIcon icon={osIcon} className="fa-fw mr-2" />}
        {osName}
        {' '}
        {osVersion && `(OS Version: ${osVersion})`}
      </div>
      <div className="device-info">
        <FontAwesomeIcon icon={deviceIcon} className="fa-fw mr-2" />
        {deviceType}
      </div>
    </div>
  );
};

export default renderBrowserAndOSIcons;
