import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import withContent from '../withContent';
import { getCampaigns } from '../../apis/campaigns.ts';
import ManageCampaignModalComponent from '../../components/Campaigns/ManageCampaignModalComponent/ManageCampaignModalComponent';
import CampaignsListComponent from '../../components/Campaigns/CampaignsListComponent/CampaignsListComponent';
import './CampaignsPage.scss';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
// import { useDispatch, useSelector } from 'react-redux';
// import {setCampaignsState} from '../../redux/reducers/actions/campaignsActions'
// import {getCampaignsState, getTotalCampaignsState} from '../../redux/reducers/selectors/campaigns'

function CampaignsPage() {
  const [campaigns, setCampaigns] = useState();
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const toast = useToast();
  // const dispatch = useDispatch();
  // const campaigns = useSelector(getCampaignsState)
  // const totalCampaigns = useSelector(getTotalCampaignsState)

  useEffect(() => {
    async function fetchData() {
      const response = await getCampaigns();
      if (!Array.isArray(response?.campaigns) && response?.campaigns !== null) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting campaings', life: 3000,
        });
        setError(true);
      }
      setCampaigns(response);
      // const campaigns = await getCampaigns()
      // dispatch(setCampaignsState(campaigns))
    }
    fetchData();
  }, []);

  return (
    <>
      <Button className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New campaign" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      {visible && <ManageCampaignModalComponent visible={visible} setVisible={setVisible} />}
      {error && (
        <InfoComponent title="An error occurred" message="Please try refreshing the page. If the error persists, please contact us" severity="error" />
      )}
      <TabView>
        <TabPanel header="Active campaigns" leftIcon="pi pi-eye mr-2">
          {campaigns === undefined ? <CustomSpinnerComponent text="campaigns" /> : (
            campaigns?.total === 0 ? (
              <InfoComponent
                title="No campaign"
                message="No campaign created yet. Let&apos;s create one!"
                severity="info"
              />
            ) : (
              <CampaignsListComponent campaigns={campaigns?.campaigns?.filter((item) => item.status !== 'Completed')} />
            )
          )}
        </TabPanel>
        <TabPanel header="Archived campaigns" leftIcon="pi pi-eye-slash mr-2">
          {campaigns === undefined ? <CustomSpinnerComponent text="campaigns" /> : (
            campaigns?.total === 0 ? (
              <InfoComponent
                title="No archived campaign"
                message="No campaign was archived yet"
                severity="info"
              />
            ) : (
              <CampaignsListComponent campaigns={campaigns?.campaigns?.filter((item) => item.status === 'Completed')} archived />
            )
          )}
        </TabPanel>
      </TabView>
    </>
  );
}

export default withContent(CampaignsPage);
