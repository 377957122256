import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import withContent from '../withContent';
import { getGroups } from '../../apis/usersAndGroups.ts';
import GroupsListComponent from '../../components/UserAndGroups/GroupsListComponent/GroupsListComponent';
import ManageGroupModalComponent from '../../components/UserAndGroups/ManageGroupModalComponent/ManageGroupModalComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';

function UserAndGroupsPage() {
  const [groups, setGroups] = useState();
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await getGroups();
      if (!Array.isArray(response?.groups) && response.total !== 0) {
        setError(true);
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting the groups', life: 3000,
        });
      }
      setGroups(response);
    }
    fetchData();
  }, []);

  return (
    <>
      <Button className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New group" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      {groups === undefined ? (
        <CustomSpinnerComponent text="groups" />
      ) : (
        <>
          {error && (
            <InfoComponent
              title="An error occurred"
              message="Please try refreshing the page. If the error persists, please contact us."
              severity="error"
            />
          )}

          {!error && groups?.total === 0 && (
            <InfoComponent
              title="No group"
              message="No groups created yet. Let&apos;s create one!"
              severity="info"
            />
          )}

          <ManageGroupModalComponent visible={visible} setVisible={setVisible} />
          <GroupsListComponent groups={groups?.groups} />
        </>
      )}
    </>
  );
}

export default withContent(UserAndGroupsPage);
