import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'primereact/chart';
import { countOccurrences, statsSMSMapping } from '../../utils';

export default function SMSDoughnutComponent({ campaign, campaignStats }) {
  const [chartDataArray, setChartDataArray] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [plugins, setPlugins] = useState([{}]);

  useEffect(() => {
    let totalError = 0;
    let totalSent = 0;
    setChartDataArray([]);
    const documentStyle = getComputedStyle(document.documentElement);

    const options = {
      cutout: '60%',
      plugins: {
        tooltip: {
          callbacks: {
            label(context) {
              const value = context.parsed || 0;
              const chartLabel = context.chart.data.datasets[0].label;
              const { dataIndex } = context;

              if (dataIndex === 1) {
                return null;
              }

              if (chartLabel === 'Error Sending SMS' || chartLabel === 'SMS Sent') {
                return `${((value / (totalSent + totalError)) * 100).toFixed(2)}% of total SMSs`;
              }
              return `${((value / totalSent) * 100).toFixed(2)}% of sent SMSs`;
            },
          },
          filter: (tooltipItem) => tooltipItem.dataIndex !== 1,
        },
      },
    };

    // eslint-disable-next-line no-shadow
    const plugins = [{
      beforeDraw(chart) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 160).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'top';
        const text = chart.config.data.datasets[0].data[0];
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    }];

    setChartOptions(options);
    setPlugins(plugins);

    if (campaign) {
      const newDataArray = Object.values(statsSMSMapping).map((value) => {
        let smsSent = campaignStats[value.name];
        if (value.name === 'Error Sending SMS') {
          smsSent = countOccurrences(value.name, campaign?.timeline);
          totalError = smsSent;
        }
        if (value.name === 'SMS Sent') {
          smsSent = countOccurrences(value.name, campaign?.timeline);
          totalSent += smsSent;
        }

        const percentage = (value.name === 'SMS Sent' || value.name === 'Error Sending SMS')
          ? smsSent
          : smsSent;
        const remainingPercentage = (value.name === 'SMS Sent' || value.name === 'Error Sending SMS')
          ? ((totalSent + totalError) - totalSent)
          : (totalSent - smsSent);

        const dataSent = {
          header: value.name,
          datasets: [
            {
              label: value.name,
              data: [percentage, remainingPercentage],
              backgroundColor: [
                documentStyle.getPropertyValue(value.color),
                documentStyle.getPropertyValue('--gray-100'),
              ],
              hoverBackgroundColor: [
                documentStyle.getPropertyValue(value.hover),
                documentStyle.getPropertyValue('--gray-100'),
              ],
            },
          ],
        };

        return dataSent;
      });

      setChartDataArray((prevDataArray) => [...prevDataArray, ...newDataArray]);
    }
  }, [campaign]);

  return (
    <div className="card flex flex-wrap gap-3 p-fluid">
      {chartDataArray.map((chartData) => (
        <div key={chartData.header} className="flex-auto md:w-1">
          {chartData.header}
          <Chart type="doughnut" data={chartData} options={chartOptions} plugins={plugins} />
        </div>
      ))}
    </div>
  );
}

SMSDoughnutComponent.propTypes = {
  campaign: PropTypes.object,
  campaignStats: PropTypes.object,
};

SMSDoughnutComponent.defaultProps = {
  campaign: null,
  campaignStats: null,
};
