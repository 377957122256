import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import ManageGroupFormComponent from '../ManageGroupFormComponent/ManageGroupFormComponent';
import UsersGroupListComponent from '../UsersGroupListComponent/UsersGroupListComponent';
import { getGroup, createGroup, editGroup } from '../../../apis/usersAndGroups.ts';
import { useToast } from '../../../context/ToastContext';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';

function ManageGroupModalComponent({ visible, setVisible, groupId }) {
  const toast = useToast();
  const [group, setGroup] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [originalGroupName, setOriginalGroupName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      if (groupId) {
        const response = await getGroup(groupId);
        if (response.response?.data.success === false) {
          toast.current.show({
            severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
          });
          setGroup(null);
          setGroupUsers([]);
          setOriginalGroupName('');
        } else {
          setGroup(response);
          setGroupUsers(response?.targets);
          setOriginalGroupName(response?.name);
        }
      }
    }
    fetchData();
  }, [groupId]);

  async function handleCreateGroup() {
    setIsSubmitting(true);

    let response = null;

    try {
      if (groupId) {
        response = await editGroup({ ...group, targets: groupUsers, id: groupId });
      } else {
        response = await createGroup({ ...group, targets: groupUsers });
      }
      if (response.id !== null || response.id !== undefined) {
        toast.current.show({
          severity: 'success', summary: 'Success', detail: groupId ? 'Editing group. Refresh the page to see the changes' : 'Creating group. Refresh the page to see the changes', sticky: true,
        });
        setVisible(false);
        navigate('/groups');
      } else {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: `There was a problem ${groupId ? 'editing' : 'creating'} the group: ${response.response.data.message}`, sticky: true,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: `Unexpected error: ${error.message}`, sticky: true,
      });
    }
    setIsSubmitting(false);
  }

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">
        {groupId ? `Edit ${originalGroupName || ''} ` : 'Create a new '}
        group
      </span>
    </div>
  );

  const footerContent = (
    <div style={{ marginBottom: '-1rem', borderTop: '2px solid #ccc' }}>
      {group === null && groupId ? null : (
        <Button
          label={isSubmitting ? 'Saving...' : 'Save'}
          icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
          style={{ marginTop: '0.5rem' }}
          disabled={isSubmitting}
          type="button"
          onClick={() => handleCreateGroup()}
          autoFocus
        />
      )}
    </div>
  );

  return (
    <Dialog visible={visible} modal draggable={false} header={headerElement} footer={footerContent} style={{ width: '70rem' }} onHide={() => setVisible(false)}>
      {group === null && groupId ? <CustomSpinnerComponent text="group" /> : (
        <>
          <ManageGroupFormComponent
            setGroup={setGroup}
            group={group}
            setGroupUsers={setGroupUsers}
          />
          <UsersGroupListComponent setGroupUsers={setGroupUsers} groupUsers={groupUsers} />
        </>
      )}
    </Dialog>
  );
}

ManageGroupModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  groupId: PropTypes.number,
};

ManageGroupModalComponent.defaultProps = {
  groupId: null,
};

export default ManageGroupModalComponent;
