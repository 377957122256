import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import ManageWebhooksFormComponent from '../ManageWebhooksFormComponent/ManageWebhooksFormComponent';
import { getWebhook } from '../../../apis/webhooks.ts';
import CustomSpinnerComponent from '../../CustomSpinnerComponent/CustomSpinnerComponent';
import { useToast } from '../../../context/ToastContext';

function ManageWebhooksModalComponent({
  visible, setVisible, webhookId, mode,
}) {
  const [webhook, setWebhook] = useState();
  const toast = useToast();
  useEffect(() => {
    async function fetchData() {
      if (webhookId) {
        const response = await getWebhook(webhookId);
        if (response.response?.data.success === false) {
          toast.current.show({
            severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
          });
          setWebhook(null);
        } else {
          setWebhook(response);
        }
      }
    }
    fetchData();
  }, [webhookId]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">
        {mode === 'edit' ? `Edit ${webhook?.name ? webhook.name : ''} ` : 'Create a new'}
        {' '}
        webhook
      </span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      modal
      draggable={false}
      header={headerElement}
      style={{ width: '70rem' }}
      onHide={() => setVisible(false)}
    >
      {webhook === null ? null : webhook === undefined && mode === 'edit' ? (
        <CustomSpinnerComponent text="webhook" />
      ) : (
        <ManageWebhooksFormComponent webhook={webhook} mode={mode} setVisible={setVisible} />
      )}
    </Dialog>
  );
}

ManageWebhooksModalComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  webhookId: PropTypes.number,
  mode: PropTypes.string,
};

ManageWebhooksModalComponent.defaultProps = {
  webhookId: null,
  mode: null,
};

export default ManageWebhooksModalComponent;
