import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withContent from '../withContent';
import './UserInterfacePage.scss';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';

function UserInterfacePage() {
  const [checkedMap, setCheckedMap] = useState(localStorage.getItem('gophish.use_map') === 'true');
  const [checkedPolarArea, setCheckedPolarArea] = useState(localStorage.getItem('gophish.see_polar_area_graph') === 'true');
  const [checkedDoughnuts, setCheckedDoughnuts] = useState(localStorage.getItem('gophish.see_doughnuts_graphs') === 'true');
  const [checkedTimeline, setCheckedTimeline] = useState(localStorage.getItem('gophish.see_timeline_graph') === 'true');
  const navigate = useNavigate();
  const { role } = useSelector(getUserInfoState);

  const handleMapCheckboxChange = () => {
    const newValue = !checkedMap;
    localStorage.setItem('gophish.use_map', newValue);
    setCheckedMap(newValue);
  };

  const handleTimelineCheckboxChange = () => {
    const newValue = !checkedTimeline;
    localStorage.setItem('gophish.see_timeline_graph', newValue);
    setCheckedTimeline(newValue);
  };

  const handleDoughnutsCheckboxChange = () => {
    const newValue = !checkedDoughnuts;
    localStorage.setItem('gophish.see_doughnuts_graphs', newValue);
    setCheckedDoughnuts(newValue);
  };

  const handlePolarAreaCheckboxChange = () => {
    const newValue = !checkedPolarArea;
    localStorage.setItem('gophish.see_polar_area_graph', newValue);
    setCheckedPolarArea(newValue);
  };

  if (role === 'customer') {
    navigate('/');
  }

  return (
    <div className="checkbox-container">
      <div className="checkbox-item">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="maps" className="text-500 text-lg">
          <Checkbox id="maps" inputId="maps" onChange={handleMapCheckboxChange} checked={checkedMap} />
          <span className="label-text">Show campaign&apos;s results map</span>
        </label>
      </div>

      <div className="checkbox-item">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="timeline" className="text-500 text-lg">
          <Checkbox id="timeline" inputId="timeline" onChange={handleTimelineCheckboxChange} checked={checkedTimeline} />
          <span className="label-text">Show campaign&apos;s timeline</span>
        </label>
      </div>

      <div className="checkbox-item">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="doughnuts" className="text-500 text-lg">
          <Checkbox id="doughnuts" inputId="doughnuts" onChange={handleDoughnutsCheckboxChange} checked={checkedDoughnuts} />
          <span className="label-text">Show Doughnuts graphs in campaigns</span>
        </label>
      </div>

      <div className="checkbox-item">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="polararea" className="text-500 text-lg">
          <Checkbox id="polararea" inputId="polararea" onChange={handlePolarAreaCheckboxChange} checked={checkedPolarArea} />
          <span className="label-text">Show Polar Area graph in campaigns</span>
        </label>
      </div>
    </div>

  );
}

export default withContent(UserInterfacePage);
