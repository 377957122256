/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../apis/authentication.ts';
import { useToast } from '../../context/ToastContext';

function ResetPasswordPage() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const validationSchema = yup.object().shape({
    newPassword: yup.string()
      .test('password-min-length', 'Password must be at least 8 characters', (value) => {
        if (dirtyFields.newPassword) {
          return value?.length > 7;
        }
        return true;
      })
      .test('password-different', 'Passwords must be different from the old one', (value, ctx) => {
        if (dirtyFields.newPassword) {
          return ctx.parent.oldPassword !== value;
        }
        return true;
      }),
    confirmPassword: yup.string()
      .test('password-match', 'Passwords must match', (value, ctx) => {
        // if (dirtyFields.newPassword && value) {
        if (dirtyFields.newPassword) {
          return ctx.parent.newPassword === value;
        }
        return true;
      }),
  });

  const defaultValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const {
    handleSubmit, trigger, getValues, control, formState: { errors, dirtyFields, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = async () => {
    const data = getValues();
    const response = await resetPassword(data);
    if (response.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      navigate('/');
    }
  };

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          {/* <img src="/assets/tenants/repsol.svg" alt="logo" height={50} className="mb-3" /> */}
          <div className="text-900 text-3xl font-medium mb-3">Reset Password</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="newPassword"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label className="block text-900 font-medium mb-2 text-left mt-3" htmlFor={field.name}>
                    New password
                  </label>
                  <span className="p-input-icon-right w-full">
                    <InputText
                      id={field.name}
                      type={showNewPassword ? 'text' : 'password'}
                      value={field.value}
                      className={`w-full ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger(['newPassword', 'confirmPassword']); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <i
                      className={showNewPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      tabIndex="0"
                      role="button"
                      aria-label="Toggle New Password Visibility"
                      onKeyDown={(e) => e.key === 'Enter' && setShowNewPassword(!showNewPassword)}
                    />
                  </span>
                  <div>
                    {getFormErrorMessage(field.name)}
                  </div>
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label className="block text-900 font-medium mb-2 text-left mt-3" htmlFor={field.name}>
                    Confirm password
                  </label>
                  <span className="p-input-icon-right w-full">
                    <InputText
                      id={field.name}
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={field.value}
                      className={`w-full ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger('confirmPassword'); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <i
                      className={showConfirmPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      tabIndex="0"
                      role="button"
                      aria-label="Toggle Confirm Password Visibility"
                      onKeyDown={(e) => e.key === 'Enter' && setShowConfirmPassword(!showConfirmPassword)}
                    />
                  </span>
                  <div>
                    {getFormErrorMessage(field.name)}
                  </div>
                </>
              )}
            />
          </div>
          <Button label="Reset" icon="pi pi-refresh" className="w-full mt-3" type="submit" disabled={!isValid} />
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
