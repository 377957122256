import './App.scss';
import { PrimeReactProvider } from 'primereact/api';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { getNoAuthRoutes, getAuthRoutes } from './routes';
import ToolBarComponent from './components/ToolBarComponent/ToolBarComponent';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { ToastProvider } from './context/ToastContext';
import { persistor } from './redux/store/store';

function App() {
  const location = useLocation();

  return (
    <PersistGate loading={null} persistor={persistor}>
      <PrimeReactProvider>
        <ToastProvider>
          <div className="App">
            {location.pathname !== '/login'
            && (
              <header className="App-header">
                <ToolBarComponent />
              </header>
            )}
            <Routes>
              {getNoAuthRoutes().map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
              {getAuthRoutes().map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={(
                    <ProtectedRoute element={route.element} />
                )}
                />
              ))}
            </Routes>
          </div>
        </ToastProvider>
      </PrimeReactProvider>
    </PersistGate>
  );
}

export default App;
