import { Tag } from 'primereact/tag';

export function formatDate(dateString) {
  if (dateString.startsWith('0001')) {
    return '-';
  }
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  return date.toLocaleDateString('en-ES', options);
}

const ownerTypes = {
  me: {
    label: 'Me',
    color: 'info',
  },
  shared: {
    label: 'Shared',
    color: 'success',
  },
};

export const ownerInfo = (rowData, id) => {
  const owner = rowData.owner === id ? 'me' : 'shared';
  return (
    <Tag
      value={ownerTypes[owner].label}
      severity={ownerTypes[owner].color}
    />
  );
};
