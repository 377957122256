import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { createWebhook, editWebhook } from '../../../apis/webhooks.ts';
import { useToast } from '../../../context/ToastContext';

function ManageWebhooksFormComponent({ webhook, mode, setVisible }) {
  const [showSecret, setShowSecret] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'Webhook name must be at least 2 characters').max(255, 'Webhook name must be at most 255 characters').required('Webhook name is required'),
    url: yup.string().max(1000, 'Webhook URL must be at most 1000 characters').required('URL is required'),
    secret: yup.string(),
  });

  const defaultValues = {
    name: '',
    url: '',
    secret: '',
    isActive: false,
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (webhook) {
      reset({
        name: mode === 'edit' ? webhook.name : '',
        url: webhook.url,
        secret: webhook.secret,
        isActive: webhook.is_active,
      });
    }
  }, [webhook]);

  async function onSubmit() {
    setIsSubmitting(true);
    const data = getValues();
    let response = {};
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    if (mode === 'edit') {
      response = await editWebhook({
        ...sanitizedSendData, id: webhook.id, is_active: data.isActive,
      });
    } else {
      response = await createWebhook({
        ...sanitizedSendData, is_active: data.isActive,
      });
    }
    if (response.response?.data.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: mode === 'edit' ? 'Webhook edited' : 'Webhook created', life: 3000,
      });
      setVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
      <div>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                Name*
              </label>
              <InputText
                id={field.name}
                placeholder="Name"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                aria-describedby={`${field.name}-info`}
              />
              <div className="mb-1">
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="url"
          control={control}
          render={({ field, fieldState }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                URL*
              </label>
              <InputText
                id={field.name}
                placeholder="https://mycompany.com/webhook/"
                value={field.value}
                className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                aria-describedby={`${field.name}-info`}
              />
              <div className="mb-1">
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="secret"
          control={control}
          render={({ field }) => (
            <div className="mb-2">
              <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                Secret
              </label>
              <span className="p-input-icon-right">
                <InputText
                  id={field.name}
                  placeholder="Secret"
                  type={showSecret ? 'text' : 'secret'}
                  value={field.value}
                  className="w-full md:w-30rem"
                  onChange={(e) => { field.onChange(e.target.value); }}
                  aria-describedby={`${field.name}-info`}
                />
                <i
                  className={showSecret ? 'pi pi-eye-slash' : 'pi pi-eye'}
                  onClick={() => setShowSecret(!showSecret)}
                  tabIndex="0"
                  role="button"
                  aria-label="Toggle Secret Visibility"
                  onKeyDown={(e) => e.key === 'Enter' && setShowSecret(!showSecret)}
                />
              </span>
              <div>
                {getFormErrorMessage(field.name)}
              </div>
            </div>
          )}
        />
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <label htmlFor={field.name}>
              <Checkbox
                id={field.name}
                inputId={field.name}
                checked={field.value}
                inputRef={field.ref}
                className="mr-2"
                onChange={(e) => field.onChange(e.checked)}
              />
              Is Active
              <Tooltip target=".custom-target-icon" className="w-3" />
              <i
                className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                data-pr-tooltip="Data is sent only to the active webhooks"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center+7"
                style={{ cursor: 'pointer' }}
              />
            </label>
          )}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
          <Button
            label={isSubmitting ? 'Saving...' : 'Save'}
            icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
            disabled={!isValid || isSubmitting}
            type="button"
            onClick={() => onSubmit()}
            autoFocus
          />
        </div>
      </div>
    </form>
  );
}

ManageWebhooksFormComponent.propTypes = {
  webhook: PropTypes.object,
  mode: PropTypes.string,
  setVisible: PropTypes.func.isRequired,
};

ManageWebhooksFormComponent.defaultProps = {
  webhook: {},
  mode: null,
};

export default ManageWebhooksFormComponent;
