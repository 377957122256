/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import withContent from '../withContent';
import { useToast } from '../../context/ToastContext';
import { resetApiKey, settings } from '../../apis/authentication.ts';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';
import { setUserInfoState } from '../../redux/reducers/actions/userInfoActions';

function AccountSettingsPage() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const userInfo = useSelector(getUserInfoState);
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    username: yup.string().min(2, 'Username must be at least 2 characters').max(255, 'Username must be at most 255 characters').required('Username is required'),
    oldPassword: yup.string(),
    newPassword: yup.string()
      .test('password-min-length', 'Password must be at least 8 characters', (value) => {
        if (dirtyFields.newPassword) {
          return value?.length > 7;
        }
        return true;
      })
      .test('password-different', 'Passwords must be different from the old one', (value, ctx) => {
        if (dirtyFields.newPassword) {
          return ctx.parent.oldPassword !== value;
        }
        return true;
      }),
    confirmPassword: yup.string()
      .test('password-match', 'Passwords must match', (value, ctx) => {
        // if (dirtyFields.newPassword && value) {
        if (dirtyFields.newPassword) {
          return ctx.parent.newPassword === value;
        }
        return true;
      }),
  });

  const defaultValues = {
    username: userInfo.name,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    apikey: useSelector(getUserInfoState).apiKey,
  };

  const {
    control,
    formState: { errors, dirtyFields, isValid },
    handleSubmit,
    trigger,
    setValue,
    register,
    getValues,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function onSubmit(data) {
    setIsSubmitting(true);
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    const response = await settings(sanitizedSendData);
    if (response.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: 'Acount settings updated', life: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  async function generateApiKey() {
    const response = await resetApiKey();
    if (response.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: 'Acount settings updated', life: 3000,
      });
      setValue('apikey', response.data);
      dispatch(setUserInfoState({ apiKey: response.data }));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(getValues().apikey)
      .then(() => {
        toast.current.show({
          severity: 'success', summary: 'Copied', detail: 'Text copied to clipboard!', life: 3000,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: `Failed to copy text to clipboard. ${err}`, life: 3000,
        });
      });
  };

  return (
    <div>
      <div className="font-medium text-3xl text-900 mb-3">Manage user account settings</div>
      <div className="text-500 mb-5">On this page, you can manage the settings of the current user</div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <ul className="list-none p-0 m-0">
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="apikey"
              control={control}
              render={({ field }) => (
                <>
                  <label className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>API key</label>
                  <InputText
                    disabled
                    id={field.name}
                    value={field.value}
                    style={{ width: '60%' }}
                    aria-describedby={`${field.name}-info`}
                  />
                  <Button type="button" onClick={() => generateApiKey()}>Generate</Button>
                  <Button className="ml-2" type="button" icon="pi pi-copy" onClick={copyToClipboard} />
                  <div />
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>Username</label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <InputText
                      disabled
                      id={field.name}
                      value={field.value}
                      className={`w-full md:w-25rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger('username'); }}
                      aria-describedby={`${field.name}-info`}
                    />
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="oldPassword"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>
                    Old password
                  </label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <span className="p-input-icon-right">
                      <InputText
                        id={field.name}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register(field.name)}
                        type={showOldPassword ? 'text' : 'password'}
                        value={field.value}
                        className={`w-full md:w-25rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger('oldPassword'); }}
                        aria-describedby={`${field.name}-info`}
                      />
                      <i
                        className={showOldPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        tabIndex="0"
                        role="button"
                        aria-label="Toggle Old Password Visibility"
                        onKeyDown={(e) => e.key === 'Enter' && setShowOldPassword(!showOldPassword)}
                      />
                    </span>
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="newPassword"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>
                    New password
                  </label>
                  <div className="w-full md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <span className="p-input-icon-right">
                      <InputText
                        id={field.name}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register(field.name)}
                        type={showNewPassword ? 'text' : 'password'}
                        value={field.value}
                        className={`w-full md:w-25rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger(['newPassword', 'confirmPassword']); }}
                        aria-describedby={`${field.name}-info`}
                      />
                      <i
                        className={showNewPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        tabIndex="0"
                        role="button"
                        aria-label="Toggle New Password Visibility"
                        onKeyDown={(e) => e.key === 'Enter' && setShowNewPassword(!showNewPassword)}
                      />
                    </span>
                  </div>
                </>
              )}
            />
          </li>
          <li className="flex align-items-center py-3 px-2 border-300 flex-wrap">
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label style={{ paddingTop: '20px' }} className="text-500 w-6 md:w-2 font-medium" htmlFor={field.name}>
                    Confirm password
                  </label>
                  <div className="w-2 md:w-10 md:flex-order-0 flex-order-1">
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                    <span className="p-input-icon-right">
                      <InputText
                        id={field.name}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register(field.name)}
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={field.value}
                        className={`w-full md:w-25rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger('confirmPassword'); }}
                        aria-describedby={`${field.name}-info`}
                      />
                      <i
                        className={showConfirmPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        tabIndex="0"
                        role="button"
                        aria-label="Toggle Confirm Password Visibility"
                        onKeyDown={(e) => e.key === 'Enter' && setShowConfirmPassword(!showConfirmPassword)}
                      />
                    </span>
                  </div>
                </>
              )}
            />
          </li>
        </ul>
        <Button
          label={isSubmitting ? 'Saving...' : 'Save'}
          icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
          className="w-2 md:w-2 m-6"
          disabled={!isValid || isSubmitting}
          type="submit"
          autoFocus
        />
      </form>
    </div>
  );
}

export default withContent(AccountSettingsPage);
