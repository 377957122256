import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ManageSendingProfilesModalComponent from '../ManageSendingProfilesModalComponent/ManageSendingProfilesModalComponent';
import { formatDate } from '../../utils';
import { deleteSmtpGateway, deleteSmsGateway } from '../../../apis/sendingProfiles.ts';
import { useToast } from '../../../context/ToastContext';

async function acceptDeleteSendingProfile(sendingProfile, toast) {
  let response = {};
  switch (sendingProfile.interface_type) {
    case 'SMTP':
      response = await deleteSmtpGateway(sendingProfile.id);
      break;
    case 'SMS Gateway':
      response = await deleteSmsGateway(sendingProfile.id);
      break;
    default:
  }
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to remove the sending profile: ${response.response.data.message}` : 'An unexpected error occurred deleting the sending profile', life: 3000,
    });
  }
}

const deleteSendingProfileConfirmation = (sendingProfile, toast) => {
  confirmDialog({
    message: 'Do you want to delete this sending profile?',
    header: `Delete ${sendingProfile.name} sending profile`,
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteSendingProfile(sendingProfile, toast),
  });
};

function SendingProfilesListComponent({ sendingProfiles }) {
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const [selectedSendingProfile, setSelectedSendingProfile] = useState([]);
  const settingsRef = useRef(null);
  const toast = useToast();

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    modified_date: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Sending profiles</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const header = renderHeader();
  const dateBodyTemplate = (rowData) => formatDate(rowData.modified_date);
  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
  const actions = () => (
    [
      {
        label: 'Make a copy',
        icon: 'pi pi-copy',
        command: () => { setMode('copy'); setVisible(true); },
      },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => { setMode('edit'); setVisible(true); },
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => { deleteSendingProfileConfirmation(selectedSendingProfile, toast); },
      },
    ]);

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-cog" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} onClick={(e) => { settingsRef.current.toggle(e); setSelectedSendingProfile(value); }}>
      <TieredMenu model={actions()} popup ref={settingsRef} style={{ width: 'auto' }} />
    </Button>
  );

  return (
    sendingProfiles?.length > 0 && (
      <>
        <ConfirmDialog />
        <ManageSendingProfilesModalComponent
          visible={visible}
          setVisible={setVisible}
          sendingProfileId={selectedSendingProfile.id}
          sendingProfileInterfaceType={selectedSendingProfile.interface_type}
          mode={mode}
        />
        <DataTable
          size="small"
          value={sendingProfiles}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selectionMode="checkbox"
          selection={selectedSendingProfile}
          onSelectionChange={(e) => setSelectedSendingProfile(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={['name', 'num_targets', 'modified_date']}
          emptyMessage="No sending profiles found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} sending profiles"
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '14rem' }} />
          <Column field="interface_type" header="Interface type" sortable style={{ minWidth: '4rem' }} />
          <Column field="modified_date" header="Last modification" sortable dataType="date" style={{ minWidth: '12rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={(e) => actionBodyTemplate(e)} />
        </DataTable>
      </>
    )
  );
}

SendingProfilesListComponent.propTypes = {
  sendingProfiles: PropTypes.array,
};

SendingProfilesListComponent.defaultProps = {
  sendingProfiles: [],
};

export default SendingProfilesListComponent;
