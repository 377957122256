import axiosHandler from './index';
import axiosHandlerAuth from './indexInternal';
import { store } from '../redux/store/store';
import { setUserInfoState } from '../redux/reducers/actions/userInfoActions';

const getUsersManagement = () => {
  return axiosHandler.get('/users/');
}

const getUserManagement = (id) => {
  return axiosHandler.get(`/users/${id}`);
}

const createUserManagement = (data) => {
  return axiosHandler.post('/users/', data);
}

const editUserManagement = (data) => {
  return axiosHandler.put(`/users/${data.id}`, data);
}

const deleteUserManagement = (id) => {
  return axiosHandler.delete(`/users/${id}`);
}

const impersonateUserManagement = async (data) => {
  try {
    const response = await axiosHandlerAuth.post('/impersonate', {
      username: data.username,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
    if (response?.success) {
      store.dispatch(setUserInfoState({ apiKey: response.data.apiKey, id: response.data.id }));
      return response
    } else {
      const errorMessage = response.response?.data.message || response.response?.data;
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const errorMessage = error.response?.data.message || error.response?.data;
    return { success: false, message: errorMessage };
  }
};

export { getUsersManagement, getUserManagement, createUserManagement, editUserManagement, deleteUserManagement, impersonateUserManagement };
