import axiosHandler from './index';

const getReportingSettings = () => {
    return axiosHandler.get('/imap/')
}

const createReportingSettings = (data) => {
    return axiosHandler.post('/imap/', data)
}

export { getReportingSettings, createReportingSettings }