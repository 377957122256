import React, { useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
// import { Avatar } from 'primereact/avatar';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../apis/authentication.ts';
import { useToast } from '../../context/ToastContext';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';
import { cleanUserInfoState } from '../../redux/reducers/actions/userInfoActions';

export default function ToolBarComponent() {
  const toast = useToast();
  const userInfo = useSelector(getUserInfoState);
  const navigate = useNavigate();
  const menu = useRef(null);
  const user = useRef(null);
  // const lang = useRef(null);
  const dispatch = useDispatch();

  if (userInfo.apiKey === '') {
    return;
  }

  const logoutHandler = async () => {
    const response = await logout();
    if (response.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      dispatch(cleanUserInfoState());
      window.location.href = '/login';
    }
  };
  const items = [
    {
      label: 'User Guide',
      icon: 'pi pi-book',
      command: () => { window.open('https://docs.getgophish.com/user-guide', '_blank'); },
    },
    {
      label: 'API Documentation',
      icon: 'pi pi-code',
      command: () => { window.open('https://docs.getgophish.com/api-documentation', '_blank'); },
    },
  ];
  const userItems = [
    {
      label: 'Account Settings',
      icon: 'pi pi-cog',
      command: () => { navigate('/account-settings'); },
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => { logoutHandler(); },
    },
  ];
  // const languageItems = [
  //   {
  //     label: 'Español',
  //     command: () => { console.log('Load Spanish'); },
  //   },
  //   {
  //     label: 'English',
  //     command: () => { console.log('Load English'); },
  //   },
  // ];
  const startContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <img src="/assets/tenants/repsol.svg" alt="Company Logo" style={{ width: '100px', height: 'auto', marginRight: '10px' }} /> */}
    </div>
  );

  const centerContent = (
    <div className="flex flex-wrap align-items-center gap-3">
      {/* Link to Tier8 or company page?
      <button type="button" className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200" aria-label="Go home">
        <i className="pi pi-home text-2xl" />
      </button> */}
      {/* Link to Quicksight?
      <button type="button" className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200" aria-label="Go to AWS stats">
        <i className="pi pi-chart-line text-2xl" />
      </button> */}
      <button type="button" onClick={(e) => menu.current.toggle(e)} className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200" aria-label="Deploy help options menu">
        <i className="pi pi-question-circle text-2xl" />
        <TieredMenu model={items} popup ref={menu} style={{ width: 'auto' }} />
      </button>
      {/* <button type="button" onClick={(e) => lang.current.toggle(e)} className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200" aria-label="Deploy language options menu">
        <i className="pi pi-language text-2xl" />
        <TieredMenu model={languageItems} popup ref={lang} style={{ width: 'auto' }} />
      </button> */}
    </div>
  );

  const endContent = (
    <div role="menu" tabIndex={0} className="flex align-items-center gap-2" onClick={(e) => user.current.toggle(e)} onKeyDown={(e) => user.current.toggle(e)} aria-label="Deploy user options menu">
      {/* <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" shape="circle" /> */}
      <span className="font-bold text-bluegray-50">{userInfo?.name}</span>
      <TieredMenu model={userItems} popup ref={user} style={{ width: 'auto' }} />
    </div>
  );

  return (
    <div className="card">
      <Toolbar aria-label="Actions toolbar" start={startContent} center={centerContent} end={endContent} style={{ borderRadius: '1rem', marginTop: '-1px', backgroundImage: 'linear-gradient(to right, var(--bluegray-500), var(--bluegray-800))' }} />
    </div>
  );
}
