import React from 'react';
import PropTypes from 'prop-types';

function InfoComponent({ title, message, severity }) {
  const icon = severity === 'error' ? 'pi pi-times-circle' : 'pi pi-info-circle';
  return (
    <div className={`p-message p-component p-message-${severity} p-message-enter-done`} role="alert" aria-live="assertive" aria-atomic="true" data-pc-name="messages" data-pc-section="root">
      <div className="p-message-wrapper" data-pc-section="wrapper">
        <span className={icon} style={{ fontSize: '1.5rem', verticalAlign: 'middle', marginRight: '0.5rem' }} />
        <span className="p-message-summary vertical-align-middle" data-pc-section="summary">{title}</span>
        <span className="p-message-detail vertical-align-middle" data-pc-section="detail">{message}</span>
      </div>
    </div>
  );
}

InfoComponent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
};

export default InfoComponent;
