import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { completeCampaign } from '../../../../apis/campaigns.ts';
import { deleteCampaignConfirmation } from '../../utils';
import { useToast } from '../../../../context/ToastContext';

// Exports campaign results as a CSV file
function exportAsCSV(scope, campaign) {
  let csvScope = null;
  const filename = `${campaign.name} - ${scope.charAt(0).toUpperCase()}${scope.slice(1)}.csv`;

  switch (scope) {
    case 'results':
      csvScope = campaign.results;
      break;
    case 'events':
      csvScope = campaign.timeline;
      break;
    default:
      return;
  }

  if (!csvScope) {
    return;
  }

  const csvString = Papa.unparse(csvScope, {
    escapeFormulae: true,
  });
  const csvData = new Blob([csvString], {
    type: 'text/csv;charset=utf-8;',
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, filename);
  } else {
    const csvURL = window.URL.createObjectURL(csvData);
    const dlLink = document.createElement('a');
    dlLink.href = csvURL;
    dlLink.setAttribute('download', filename);

    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  }
}

function ActionsSectionComponent({ campaign }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();

  async function completeCampaignAction(campaignId) {
    setIsSubmitting(true);
    const response = await completeCampaign(campaignId);
    if (response.success) {
      toast.current.show({
        severity: 'success', summary: 'Success', detail: response.message, life: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setIsSubmitting(false);
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem completing the campaign: ${response.response.data.message}` : 'An unexpected error occurred  completing the campaign', life: 3000,
      });
    }
  }

  const actions = () => (
    [
      {
        label: 'Results',
        command: () => { exportAsCSV('results', campaign); },
      },
      {
        label: 'Raw events',
        command: () => { exportAsCSV('events', campaign); },
      },
    ]);

  return (
    <div className="p-2 flex flex-wrap gap-2 align-items-center mb-6">
      <Button label="Back" icon="pi pi-arrow-left" className="gap-2" severity="secondary" onClick={() => navigate('/campaigns')} />
      <Button label="Export CSV" icon="pi pi-file-excel" className="gap-2" severity="success" onClick={(e) => { exportRef.current.toggle(e); }}>
        <i className="pi pi-angle-down" />
        <TieredMenu model={actions()} popup ref={exportRef} style={{ width: 'auto' }} />
      </Button>
      <Button label={isSubmitting ? 'Completing...' : (campaign.status === 'Completed' ? 'Completed' : 'Complete')} icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-flag'} disabled={campaign.status === 'Completed' || isSubmitting} className="gap-2" severity="info" onClick={() => completeCampaignAction(campaign.id)} />
      <Button label="Delete" icon="pi pi-trash" className="gap-2" severity="danger" onClick={() => deleteCampaignConfirmation(campaign.id, toast, navigate)} />
      <Button label="Refresh" icon="pi pi-refresh" className="gap-2" severity="help" onClick={() => window.location.reload()} />
    </div>
  );
}

ActionsSectionComponent.propTypes = {
  campaign: PropTypes.object,
};

ActionsSectionComponent.defaultProps = {
  campaign: {},
};

export default ActionsSectionComponent;
