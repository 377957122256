import axiosHandler from './index';

const getGroups = () => {
    return axiosHandler.get('/groups/summary');
}

const getGroup = (id) => {
    return axiosHandler.get(`/groups/${id}`);
}

const createGroup = (data) => {
    return axiosHandler.post('/groups/', data);
}

const editGroup = (data) => {
    return axiosHandler.put(`/groups/${data.id}`, data);
}

const deleteGroup = (id) => {
    return axiosHandler.delete(`/groups/${id}`);
}

export { getGroups, getGroup, createGroup, editGroup, deleteGroup };
