import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'primereact/chart';
import { countOccurrences, statsMapping } from '../../utils';

export default function PolarAreaComponent({ campaign, campaignStats }) {
  const [chartDataArray, setChartDataArray] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    if (campaign) {
      const newDataArray = Object.values(statsMapping).map((value) => {
        let emailSent = campaignStats[value.name];
        if (value.name === 'Email Sent') {
          emailSent = countOccurrences(value.name, campaign?.timeline);
        }
        return emailSent;
      });
      const data = {
        datasets: [
          {
            data: newDataArray,
            backgroundColor: [
              documentStyle.getPropertyValue(statsMapping.error.color),
              documentStyle.getPropertyValue(statsMapping.sent.color),
              documentStyle.getPropertyValue(statsMapping.opened.color),
              documentStyle.getPropertyValue(statsMapping.email_reported.color),
              documentStyle.getPropertyValue(statsMapping.clicked.color),
              documentStyle.getPropertyValue(statsMapping.submitted_data.color),
            ],
          },
        ],
        labels: [
          statsMapping.error.name,
          statsMapping.sent.name,
          statsMapping.opened.name,
          statsMapping.email_reported.name,
          statsMapping.clicked.name,
          statsMapping.submitted_data.name,
        ],
      };
      setChartDataArray(data);
    }
    const options = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        r: {
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    setChartOptions(options);
  }, [campaign]);

  return (
    <div className="card flex justify-content-center">
      <Chart type="polarArea" data={chartDataArray} options={chartOptions} style={{ position: 'relative', width: '50%' }} />
    </div>
  );
}

PolarAreaComponent.propTypes = {
  campaign: PropTypes.object,
  campaignStats: PropTypes.object,
};

PolarAreaComponent.defaultProps = {
  campaign: null,
  campaignStats: null,
};
