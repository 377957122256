import React from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import { getDistance } from 'geolib';
import { getScreenWidth } from '../../utils';

const customIcon = new Icon({ iconUrl: markerIconPng, iconSize: [12, 20] });

const clusterMarkers = (markers, radius) => {
  const clusters = [];

  markers.forEach((marker) => {
    let addedToCluster = false;

    for (const cluster of clusters) {
      const distance = getDistance(
        { latitude: cluster[0].latitude, longitude: cluster[0].longitude },
        { latitude: marker.latitude, longitude: marker.longitude },
      );

      if (distance < radius) {
        cluster.push(marker);
        addedToCluster = true;
        break;
      }
    }

    if (!addedToCluster) {
      clusters.push([marker]);
    }
  });

  return clusters;
};

function MapChartComponent({ results }) {
  const markers = results.results.filter((item) => item.ip !== '').map((item) => ({
    latitude: item.latitude,
    longitude: item.longitude,
    ip: item.ip,
    id: item.id,
  }));

  const clusters = clusterMarkers(markers, 1000); // Cluster markers arround 1000 meters

  return (
    <MapContainer
      center={[30, 0]}
      zoom={getScreenWidth() < '1281' ? 2.2 : 2.5}
      style={{ height: getScreenWidth() < '1281' ? '600px' : '1200px', width: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {clusters.map((cluster, index) => {
        const latitude = cluster.reduce((sum, marker) => sum + marker.latitude, 0) / cluster.length;
        const longitude = cluster.reduce((sum, marker) => sum + marker.longitude, 0) / cluster.length;

        const ips = cluster.map((marker) => marker.ip).join(', ');

        return (
          <Marker key={index} position={[latitude, longitude]} icon={customIcon}>
            <Popup>
              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                IPs within approximately 1km of this location:
                <br />
                {ips.trimEnd().replace(/,\s*$/, '')}
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
}

MapChartComponent.propTypes = {
  results: PropTypes.object,
};

MapChartComponent.defaultProps = {
  results: null,
};

export default MapChartComponent;
