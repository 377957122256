import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './ResultsListComponent.scss';
import { Accordion, AccordionTab } from 'primereact/accordion';
import PropTypes from 'prop-types';

// import ReplayCredentialsModalComponent from './ReplayCredentialsModalComponent.jsx';

const escapeHtml = (unsafe) => unsafe
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#039;');

function TimelineEventResults({ details }) {
  if (!details.data) {
    return null;
  }

  const parsedData = JSON.parse(details.data);
  const { payload } = parsedData;

  if (!payload) {
    return null;
  }

  const data = Object.keys(payload).filter((param) => param !== 'rid').map((param) => ({
    parameter: escapeHtml(param),
    value: escapeHtml(payload[param].toString()),
  }));

  return (
    <div className="timeline-event-results">
      <DataTable value={data} size="small">
        <Column field="parameter" header="Parameter" />
        <Column field="value" header="Value(s)" />
      </DataTable>
    </div>
  );
}

TimelineEventResults.propTypes = {
  details: PropTypes.object.isRequired,
};

function SubmittedDataInfoComponent(data) {
  return (
    <div className="mt-2">
      {/* <Button onClick={() => setOpenModal(true)}>Replay Credentials</Button>
      <ReplayCredentialsModalComponent openModal={openModal} setOpenModal={setOpenModal} /> */}
      <Accordion className="mt-2">
        <AccordionTab header="View Details">
          <div className="m-0">
            <TimelineEventResults details={data} />
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default SubmittedDataInfoComponent;
