import axiosHandler from './index';

const getCampaigns = (includeTest) => {
    return axiosHandler.get('/campaigns/summary', { params: { include_test: includeTest } });
}

const getCampaign = (id) => {
    return axiosHandler.get(`/campaigns/${id}`);
}

const getResults = (id) => {
    return axiosHandler.get(`/campaigns/${id}/results`);
}

const completeCampaign = (id) => {
    return axiosHandler.post(`/campaigns/${id}/complete`);
}

const deleteCampaign = (id) => {
    return axiosHandler.delete(`/campaigns/${id}`);
}

const createCampaign = (data) => {
    return axiosHandler.post(`/campaigns/`, data);
}

const reportUser = async (campaignUrl, id) => {
    try {
        const response = await fetch(`${campaignUrl}/report?rid=${id}`);
        if (!response.ok) {
            return `Failed to report: ${response.status} ${response.statusText}`;
        }
        if (response.status === 204) {
            return;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return `Error reporting: ${error.message}`
    }
};

const sendTestEmail = (data) => {
    return axiosHandler.post(`/util/send_test_email`, data);
}

export { getCampaigns, getCampaign, getResults, completeCampaign, deleteCampaign, createCampaign, reportUser, sendTestEmail };
