import { combineReducers } from 'redux';
import userInfoReducer from './userInfo';
import campaignsReducer from './campaigns';

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  campaigns: campaignsReducer,
});

export default rootReducer;
