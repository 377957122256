import axiosHandler from './index';

// SMTP related endpoints
const getSmtpGateways = () => {
    return axiosHandler.get('/smtp/');
}

const getSmtpGateway = (id) => {
    return axiosHandler.get(`/smtp/${id}`);
}

const createSmtpGateway = (data) => {
    return axiosHandler.post('/smtp/', data);
}

const deleteSmtpGateway = (id) => {
    return axiosHandler.delete(`/smtp/${id}`)
}

const editSmtpGateway = (data) => {
    return axiosHandler.put(`/smtp/${data.id}`, data);
}

// SMS Gateway related endpoints
const getSmsGateways = () => {
    return axiosHandler.get('/sms_gateway/');
}

const getSmsGateway = (id) => {
    return axiosHandler.get(`/sms_gateway/${id}`);
}

const createSmsGateway = (data) => {
    return axiosHandler.post('/sms_gateway/', data);
}

const deleteSmsGateway = (id) => {
    return axiosHandler.delete(`/sms_gateway/${id}`)
}

const editSmsGateway = (data) => {
    return axiosHandler.put(`/sms_gateway/${data.id}`, data);
}

export { getSmtpGateways, getSmtpGateway, createSmtpGateway, deleteSmtpGateway, editSmtpGateway, getSmsGateways, getSmsGateway, createSmsGateway, deleteSmsGateway, editSmsGateway };
