import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withContent from '../withContent';
import { getSmtpGateways, getSmsGateways } from '../../apis/sendingProfiles.ts';
import SendingProfilesListComponent from '../../components/SendingProfilesComponent/SendingProfilesListComponent/SendingProfilesListComponent';
import ManageSendingProfilesModalComponent from '../../components/SendingProfilesComponent/ManageSendingProfilesModalComponent/ManageSendingProfilesModalComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';
// import { useDispatch, useSelector } from 'react-redux';
// import {setCampaignsState} from '../../redux/reducers/actions/campaignsActions'
// import {getCampaignsState, getTotalCampaignsState} from '../../redux/reducers/selectors/campaigns'

function SendingProfilesPage() {
  const [sendingProfiles, setSendingProfiles] = useState();
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { role } = useSelector(getUserInfoState);
  // const dispatch = useDispatch();
  // const campaigns = useSelector(getCampaignsState)
  // const totalCampaigns = useSelector(getTotalCampaignsState)

  useEffect(() => {
    async function fetchData() {
      const smtps = await getSmtpGateways();
      if (!Array.isArray(smtps)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: smtps?.response?.data?.message ? smtps?.response?.data?.message : 'An unexpected error occurred getting SMTP profiles', life: 3000,
        });
      }
      const smsgateways = await getSmsGateways();
      if (!Array.isArray(smsgateways)) {
        toast.current.show({
          severity: 'error', summary: 'Error', detail: smsgateways?.response?.data?.message ? smsgateways?.response?.data?.message : 'An unexpected error occurred SMS Gateway profiles', life: 3000,
        });
      }
      if (!Array.isArray(smtps) && !Array.isArray(smsgateways)) {
        setError(true);
      }
      setSendingProfiles(
        [...Array.isArray(smtps) ? smtps : [], ...Array.isArray(smsgateways) ? smsgateways : []],
      );
      // const campaigns = await getCampaigns()
      // dispatch(setCampaignsState(campaigns))
    }
    fetchData();
  }, []);

  if (role === 'customer') {
    navigate('/');
  }

  return (
    <>
      <Button className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New Profile" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      <ManageSendingProfilesModalComponent visible={visible} setVisible={setVisible} />
      {sendingProfiles === undefined ? (
        <CustomSpinnerComponent text="sending profiles" />
      ) : (
        <>
          {error && (
          <InfoComponent
            title="An error occurred"
            message="Please try refreshing the page. If the error persists, please contact us."
            severity="error"
          />
          )}

          {!error && sendingProfiles?.length === 0 ? (
            <InfoComponent
              title="No profiles"
              message="No profiles created yet. Let&apos;s create one!"
              severity="info"
            />
          ) : (
            <SendingProfilesListComponent sendingProfiles={sendingProfiles} />
          )}
        </>
      )}
    </>
  );
}

export default withContent(SendingProfilesPage);
