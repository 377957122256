import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import withContent from '../withContent';
import { getLandingPages } from '../../apis/landingPages.ts';
import LandingPagesListComponent from '../../components/LandingPagesComponent/LandingPagesListComponent/LandingPagesListComponent';
import ManageLandingPagesModalComponent from '../../components/LandingPagesComponent/ManageLandingPagesModalComponent/ManageLandingPagesModalComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';

function LandingPagesPage() {
  const [landingPages, setLandingPages] = useState();
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await getLandingPages();
      if (!Array.isArray(response) && response !== null) {
        setError(true);
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting the landing pages', life: 3000,
        });
      }
      setLandingPages(response || []);
    }
    fetchData();
  }, []);

  return (
    <>
      <Button className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New Page" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      <ManageLandingPagesModalComponent visible={visible} setVisible={setVisible} mode="create" />
      {landingPages === undefined ? (
        <CustomSpinnerComponent text="landing pages" />
      ) : (
        <>
          {error && (
          <InfoComponent
            title="An error occurred"
            message="Please try refreshing the page. If the error persists, please contact us."
            severity="error"
          />
          )}

          {!error && landingPages?.length === 0 ? (
            <InfoComponent
              title="No landing page"
              message="No landing pages created yet. Let&apos;s create one!"
              severity="info"
            />
          ) : (
            <LandingPagesListComponent landingPages={landingPages} />
          )}
        </>
      )}
    </>
  );
}

export default withContent(LandingPagesPage);
