import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withContent from '../withContent';
import { getWebhooks } from '../../apis/webhooks.ts';
import WebhooksListComponent from '../../components/WebhooksComponent/WebhooksListComponent/WebhooksListComponent';
import ManageWebhooksModalComponent from '../../components/WebhooksComponent/ManageWebhooksModalComponent/ManageWebhooksModalComponent';
import { useToast } from '../../context/ToastContext';
import InfoComponent from '../../components/UtilsComponents/InfoComponent';
import CustomSpinnerComponent from '../../components/CustomSpinnerComponent/CustomSpinnerComponent';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';

function WebhooksPage() {
  const [webhooks, setWebhooks] = useState();
  const [visible, setVisible] = useState(false);
  const toast = useToast();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { role } = useSelector(getUserInfoState);

  useEffect(() => {
    async function fetchData() {
      setWebhooks(await getWebhooks());
      const response = await getWebhooks();
      if (!Array.isArray(response)) {
        setError(true);
        toast.current.show({
          severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? response?.response?.data?.message : 'An unexpected error occurred getting the webhooks', life: 3000,
        });
      }
      setWebhooks(response);
    }
    fetchData();
  }, []);

  if (role !== 'admin') {
    navigate('/');
  }

  return (
    <>
      <Button className="m-2" type="button" icon="pi pi-plus" onClick={() => setVisible(true)} label="New Webhook" aria-controls={visible ? 'dlg' : null} aria-expanded={visible} />
      <ManageWebhooksModalComponent visible={visible} setVisible={setVisible} />
      {webhooks === undefined ? (
        <CustomSpinnerComponent text="webhooks" />
      ) : (
        <>
          {error && (
          <InfoComponent
            title="An error occurred"
            message="Please try refreshing the page. If the error persists, please contact us."
            severity="error"
          />
          )}

          {!error && webhooks?.length === 0 ? (
            <InfoComponent
              title="No webhooks"
              message="No webhooks created yet. Let&apos;s create one!"
              severity="info"
            />
          ) : (
            <WebhooksListComponent webhooks={webhooks} />
          )}
        </>
      )}

    </>
  );
}

export default withContent(WebhooksPage);
