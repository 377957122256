import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
// import ManageUserModalComponent from './ManageUserModalComponent/ManageUserModalComponent';

function UsersGroupListComponent({ setGroupUsers, groupUsers }) {
  // const [visible, setVisible] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    first_name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    last_name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    position: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    phone_number: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Users</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );

  const header = renderHeader();

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-trash" severity="danger" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} onClick={() => setGroupUsers(groupUsers.filter((item) => item.email !== value.email))} />
  );

  return (
    <>
      {/* <ManageUserModalComponent visible={visible} setVisible={setVisible} /> */}
      <DataTable
        size="small"
        value={groupUsers}
        paginator
        header={header}
        rows={10}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50]}
        dataKey="email"
        selectionMode="checkbox"
        selection={selectedCampaign}
        onSelectionChange={(e) => setSelectedCampaign(e.value)}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={['first_name', 'last_name', 'email', 'position', 'phone_number']}
        emptyMessage="No users found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="first_name" header="First name" sortable filter filterPlaceholder="Search by first name" style={{ minWidth: '14rem' }} />
        <Column field="last_name" header="Last name" sortable filter filterPlaceholder="Search by last name" style={{ minWidth: '14rem' }} />
        <Column field="email" header="Email" sortable filter filterPlaceholder="Search by email" style={{ minWidth: '14rem' }} />
        <Column field="position" header="Position" sortable filter filterPlaceholder="Search by position" style={{ minWidth: '14rem' }} />
        <Column field="phone_number" header="Phone" sortable filter filterPlaceholder="Search by phone" style={{ minWidth: '14rem' }} />
        <Column body={(e) => actionBodyTemplate(e)} />
      </DataTable>
    </>
  );
}

UsersGroupListComponent.propTypes = {
  groupUsers: PropTypes.array,
  setGroupUsers: PropTypes.func.isRequired,
};

UsersGroupListComponent.defaultProps = {
  groupUsers: [],
};

export default UsersGroupListComponent;
