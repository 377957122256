import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { templateTypes } from './utils';

export const typeFilterTemplate = (options) => {
  const typeOptions = Object.values(templateTypes).map((type) => ({
    label: type.label,
    value: type.label,
  }));
  return (
    <Dropdown
      value={options.value}
      options={typeOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Select a Type"
      className="p-column-filter"
      showClear
    />
  );
};

export const typeBodyTemplate = (rowData) => {
  const templateType = rowData.type === 'SMS' ? 'SMS' : 'Email';
  return (
    <Tag
      value={templateType}
      icon={templateTypes[templateType].icon}
      severity={templateTypes[templateType].color}
    />
  );
};

export const testTypeBodyTemplate = (rowData) => {
  if (rowData.test_type) {
    return (
      <Tag
        value="Test"
        severity="success"
        className="mt-3 mb-3 mr-3"
      />
    );
  }
};
