import axiosHandler from './index';

const getWebhooks = () => {
    return axiosHandler.get('/webhooks/');
}

const getWebhook = (id) => {
    return axiosHandler.get(`/webhooks/${id}`);
}

const createWebhook = (data) => {
    return axiosHandler.post('/webhooks/', data);
}

const deleteWebhook = (id) => {
    return axiosHandler.delete(`/webhooks/${id}`)
}

const editWebhook = (data) => {
    return axiosHandler.put(`/webhooks/${data.id}`, data);
}

const pingWebhook = (id) => {
    return axiosHandler.post(`/webhooks/${id}/validate`);
}

export { getWebhooks, getWebhook, createWebhook, deleteWebhook, editWebhook, pingWebhook };
